import React, { useEffect, useState, forwardRef } from "react";
import Webcam from "react-webcam";
import { useSelector } from "react-redux";
import Close from "../Assets/Images/Close.svg";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import Arrows from "../Assets/Images/arrowIndicator.gif";

const Camera = forwardRef(
  ({ detection, startObjectDetection, result, cameraBlock }, ref) => {
    const navigate = useNavigate();

    //console.log("result",result)

    const car = useSelector((state) => state.car);

    const [retakeModal, setRetakeModal] = useState(false);
    const [detectionMsg, setDetectionMsg] = useState(detection);
    const [resultObj, setResultObj] = useState(result);
    //console.log("cameraBlocked",cameraBlocked)

    const [cameraBlocked, setCameraBlocked] = useState(false);
    const [cameraPrompt, setCameraPrompt] = useState(false);

    const handlePermissionChange = (event) => {
      const permissionStatus = event.target;
      const keyOfLC = "IsAlreadyRefreshed";

      if (permissionStatus.state === "granted") {
        if (!window.localStorage.getItem(keyOfLC)) {
          window.localStorage.setItem(keyOfLC, "1");
          if (!navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) {
            // Reload only if needed
            window.location.reload();
          }
        }
        setCameraBlocked(false);
        setCameraPrompt(false);
      } else if (permissionStatus.state === "prompt") {
        setCameraBlocked(false);
        setCameraPrompt(true);
      } else {
        window.localStorage.removeItem(keyOfLC);
        setCameraBlocked(true);
        setCameraPrompt(false);
      }
    };

    useEffect(() => {
      let isMounted = true;

      navigator.permissions.query({ name: "camera" }).then((result) => {
        if (isMounted) {
          result.onchange = handlePermissionChange;
          handlePermissionChange({ target: result }); // Initial check
        }
      });

      return () => {
        isMounted = false;
      };
    }, []);

    useEffect(() => {
      setDetectionMsg(detection);
      setResultObj(result);
    }, [detection, result]);

    // useEffect(()=>{
    //   setCountN(count)
    // },[count])

    // const defaultMsg = "Searching For car";
    // const detectionMsg = detection ? "Car Detected" : "Car not Detected";
    // const [detectionFlag, setDetectionFlag] = useState(true);
    // const videoConstraints = {
    //   facingMode: "environment",
    //   width: 1024,
    //   height: 768,
    //   video: true,
    //   // aspectRatio: {ideal: 1.777777778},
    //   frameRate: { ideal: 10, max: 10 },
    // };

    const videoConstraints = {
      facingMode: "environment",
      width: { ideal: 1280 },
      height: { ideal: 720 },
      // width: window.innerWidth > 1080 ? 1920 : window.innerWidth * 0.9,  // Higher resolution
      // height: window.innerHeight > 1920 ? 1080 : window.innerHeight * 0.8,
      // aspectRatio: { ideal: window.innerWidth / window.innerHeight },  // Dynamically set aspect ratio
      // frameRate: { ideal: 30, max: 30 },  // Higher frame rate
    };

    //console.log("result", result);

    const permissionBlocked = () => {
      setCameraBlocked(true);
      cameraBlock(true);
    };

    return (
      <>
        <div className="camera">
          <div className="focus">
            {car.retake && (
              <img
                src={Close}
                onClick={() => {
                  setRetakeModal(true);
                }}
                alt="X"
                className="close"
              />
            )}
            <p
              className="top_name "
              style={{
                background: detectionMsg.value === "D" ? "#0F871B" : "#EC6466",
              }}
            >
              <span className="focus_text">{car.data[car.count - 1].name}</span>
            </p>
            <img
              className="stencil"
              // src={resultObj // 👈 null and undefined check
              // && Object.keys(resultObj).length !== 0
              // ?resultObj.confidence !==''?
              //   resultObj.confidence  <= 30
              //     ? car.data[car.count - 1].redStencil
              //     : resultObj.confidence <= 70
              //     ? car.data[car.count - 1].stencil
              //     : resultObj.confidence >= 70 &&
              //       car.data[car.count - 1].greenStencil
              //       :car.data[car.count - 1].stencil
              //       :car.data[car.count - 1].stencil
              // }
              // src={ detectionMsg.value === "D" && resultObj.confidence &&resultObj.confidence >= 70 ?car.data[car.count - 1].greenStencil :car.data[car.count - 1].stencil}
              src={
                car.count < 9
                  ? !resultObj.confidence ||
                    detectionMsg.value === "ND" ||
                    detectionMsg.value === "S"
                    ? car.data[car.count - 1].redStencil
                    : (resultObj.confidence > 30 &&
                        resultObj.confidence < 70) ||
                      detectionMsg.value === "M"
                    ? car.data[car.count - 1].stencil
                    : (resultObj.confidence >= 70 ||
                        detectionMsg.value === "D") &&
                      car.data[car.count - 1].greenStencil
                  : car.data[car.count - 1].stencil
              }
              // src={car.count<9?detectionMsg.value === "ND" || detectionMsg.value === "S"  ? car.data[car.count - 1].redStencil :  detectionMsg.value === "M"  ? car.data[car.count - 1].stencil :  detectionMsg.value === "D" &&  car.data[car.count - 1].greenStencil:car.data[car.count - 1].stencil}
              alt=""
            />
            {/* { resultObj.car && resultObj.car_in_frame && resultObj.h_precision <=-2 && <img className="left" src={Arrows} alt='left' />}
          {  resultObj.car &&  resultObj.car_in_frame  && resultObj.h_precision >=2 && <img className="right" src={Arrows} alt='right' />}
          { resultObj.car &&  resultObj.car_in_frame  && resultObj.v_precision <=-2 && <img className="top" src={Arrows} alt='top' />}
          { resultObj.car &&  resultObj.car_in_frame  && resultObj.v_precision >=2 && <img className="bottom" src={Arrows} alt='bottom' />} */}

            {car.data[car.count - 1].toValidate ? (
              <p
                className="bottom_name"
                style={{
                  background:
                    detectionMsg.value === "S"
                      ? "#EC6466"
                      : detectionMsg.value === "ND"
                      ? "#F20505"
                      : detectionMsg.value === "D"
                      ? "#0F871B"
                      : detectionMsg.value === "M" && "#000000",
                }}
              >
                <span className="focus_text">{detectionMsg.msg}</span>
              </p>
            ) : null}
          </div>
          {retakeModal && (
            <Modal>
              <div className="modalContainer modalRotate">
                <div className="retake_modal">
                  <span>
                    Are you sure,you want to cancel the retake action?
                  </span>
                  <div className="yes_no_btn">
                    <button
                      onClick={() => {
                        navigate("/upload");
                      }}
                    >
                      Yes
                    </button>
                    <button onClick={() => setRetakeModal(false)}>No</button>
                  </div>
                </div>
              </div>
            </Modal>
          )}

          {cameraBlocked && (
            <Modal>
              <div className="modalContainer modalRotate">
                <div className="retake_modal">
                  <span>
                    Please allow camera access from your browser and refresh
                  </span>
                  <div className="yes_no_btn">
                    <button
                      style={{ backgroundColor: "#e42125" }}
                      onClick={() => window.location.reload()}
                    >
                      Refresh
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          )}

          {cameraPrompt && (
            <Modal>
              <div className="promptModal">
                <div className="alertIcon">
                  <svg
                    fill="#f32b2b"
                    height="70px"
                    width="70px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.2222 21H9.77778C6.65675 21 5.09624 21 3.97524 20.2646C3.48995 19.9462 3.07328 19.5371 2.74902 19.0607C2 17.9601 2 16.4279 2 13.3636C2 10.2994 2 8.76721 2.74902 7.6666C3.07328 7.19014 3.48995 6.78104 3.97524 6.46268C4.69555 5.99013 5.59733 5.82123 6.978 5.76086C7.63685 5.76086 8.20412 5.27068 8.33333 4.63636C8.52715 3.68489 9.37805 3 10.3663 3H13.6337C14.6219 3 15.4728 3.68489 15.6667 4.63636C15.7959 5.27068 16.3631 5.76086 17.022 5.76086C18.4027 5.82123 19.3044 5.99013 20.0248 6.46268C20.51 6.78104 20.9267 7.19014 21.251 7.6666C22 8.76721 22 10.2994 22 13.3636C22 16.4279 22 17.9601 21.251 19.0607C20.9267 19.5371 20.51 19.9462 20.0248 20.2646C18.9038 21 17.3433 21 14.2222 21ZM15.2697 9.59961C15.2697 9.1854 14.9339 8.84961 14.5197 8.84961C14.1055 8.84961 13.7697 9.1854 13.7697 9.59961V9.74316C12.2996 8.99864 10.4581 9.24066 9.22951 10.4692C7.69954 11.9992 7.69954 14.4798 9.22951 16.0098C10.7595 17.5397 13.2401 17.5397 14.77 16.0098C15.421 15.3588 15.7957 14.5337 15.8923 13.6844C15.9391 13.2728 15.6434 12.9012 15.2319 12.8544C14.8203 12.8076 14.4487 13.1033 14.4019 13.5148C14.3422 14.0392 14.112 14.5465 13.7094 14.9491C12.7652 15.8933 11.2344 15.8933 10.2902 14.9491C9.34598 14.0049 9.34598 12.4741 10.2902 11.5299C10.9379 10.8822 11.8617 10.6788 12.6828 10.9198C12.3737 11.0637 12.1928 11.4061 12.2661 11.7542C12.3515 12.1595 12.7492 12.4189 13.1546 12.3335L14.6743 12.0135C15.0213 11.9404 15.2697 11.6342 15.2697 11.2796V9.59961Z"
                      fill="#da2f2f"
                    />
                  </svg>
                </div>
                <div className="messageContainer">
                  <div className="retake_modal">
                    <span>Please allow camera access from your browser.</span>
                  </div>
                </div>
              </div>
            </Modal>
          )}

          <Webcam
            audio={false}
            ref={ref}
            forceScreenshotSourceSize
            className="video_camera"
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMedia={startObjectDetection}
            onUserMediaError={permissionBlocked}
          />
        </div>
      </>
    );
  }
);

export default Camera;
